<template>
  <div class="trainingCourse">
    <el-card class="box-card father-card" shadow="never">
      <!-- 头部区域 -->
      <el-row class="btns">
        <el-form ref="form" :model="form" inline>
          <!-- 搜索框 -->
          <el-form-item label="培训课目名称">
            <el-input v-model="form.trainName" placeholder="请输入培训课目名称" clearable></el-input>
          </el-form-item>
          <!-- 按钮区域 -->
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="search()">搜索</el-button>
            <el-button type="primary" icon="el-icon-plus" @click="addDialogFormVisible = true">添加</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <!-- 表格区域 -->
      <el-row class="tab">
        <el-table
          ref="multipleTable"
          :data="tabData"
          tooltip-effect="dark"
          style="width: 100%"
          highlight-current-row
          stripe
          border
          :header-cell-style="rowClass"
        >
          <el-table-column prop="trainName" label="培训课目名称" align="center"></el-table-column>
          <el-table-column prop="trainTeacher" label="培训老师" align="center"></el-table-column>
          <el-table-column prop="trainCompany" label="培训机构" align="center"></el-table-column>
          <el-table-column prop="trainAddress" label="培训地点" align="center"></el-table-column>
          <el-table-column prop="trainHours" label="培训时长(小时)" align="center"></el-table-column>
          <el-table-column prop="trainBeginDate" label="培训开始日期" align="center"></el-table-column>
          <el-table-column prop="trainEndDate" label="培训结束日期" align="center"></el-table-column>
          <el-table-column prop="trainMoney" label="培训费(元)" align="center"></el-table-column>
          <el-table-column prop="status" label="状态" align="center">
            <template slot-scope="scope">
              <span effect="dark" v-if="scope.row.status==='0'">{{ '新增' }}</span>
              <span type="success" effect="dark" v-else-if="scope.row.status==='1'">{{ '正常' }}</span>
              <span type="info" effect="dark" v-else>{{ '停用' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="remark" label="备注" align="center"></el-table-column>
          <el-table-column label="操作" align="center" min-width="200px">
            <template slot-scope="scope">
              <el-button size="mini" type="success" @click="look(scope.row)">查看详情</el-button>
              <el-button size="mini" type="primary" icon="el-icon-edit" @click="edit(scope.row)">修改</el-button>
              <el-button
                size="mini"
                type="danger"
                icon="el-icon-delete-solid"
                @click="open(scope.row)"
              >删除</el-button>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handlePageNumChange"
          :current-page="paging.PageNo"
          :page-sizes="[10,15, 20, 50]"
          :page-size="paging.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-row>
      <!-- 机构详情弹出框 -->
      <el-dialog :visible.sync="orderDialogFormVisible" class="order-dialog">
        <span slot="title" class="dialog-Title">{{dioTotle}}</span>
        <el-form
          :model="selectForm"
          inline
          ref="selectForm"
          :rules="selectFormRules"
          class="selectForm"
        >
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="培训课目名称" prop="trainName">
              <el-input v-model="selectForm.trainName" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="培训老师" prop="trainTeacher">
              <el-input v-model="selectForm.trainTeacher" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <el-form-item label="培训机构" prop="trainCompany">
              <el-input v-model="selectForm.trainCompany" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="培训地点" prop="trainAddress">
              <el-input v-model="selectForm.trainAddress" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
            <!-- <el-form-item label="培训时长(小时)" prop="trainHours">
              <el-input v-input-limit="1" v-model="selectForm.trainHours" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item> -->
            <el-form-item label="培训费(元)" prop="trainMoney">
              <el-input v-input-limit v-model="selectForm.trainMoney" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
<!--            <el-form-item label="培训开始日期" prop="trainBeginDate">
              <el-input
                v-model="selectForm.trainBeginDate"
                autocomplete="off"
                :disabled="isDisabled"
              ></el-input>
            </el-form-item> -->
          </el-row>
          <el-row>
            <el-form-item label="开始时间" prop="date1">
                  <el-date-picker v-model="selectForm.trainBeginDate" type="datetime" placeholder="选择开始日期" default-time="12:00:00" :disabled="isDisabled"></el-date-picker>
            </el-form-item>
             <el-col class="line" :span="2">-</el-col>
             <el-form-item label="开始时间" prop="date2">
                     <el-date-picker v-model="selectForm.trainEndDate" type="datetime" placeholder="选择结束日期" default-time="12:00:00" :disabled="isDisabled"></el-date-picker>
             </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select
                v-model="selectForm.status"
                placeholder="请选择状态"
                filterable
                clearable
                :disabled="isDisabled"
              >
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="selectForm.remark" autocomplete="off" :disabled="isDisabled"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="sure()">确 认</el-button>
        </div>
      </el-dialog>
      <!-- 添加弹出框 -->
      <el-dialog :visible.sync="addDialogFormVisible" class="add-dialog" @close="addDialogClose()">
        <span slot="title" class="dialog-Title">添加证书信息</span>
        <el-form :model="addForm" inline ref="addForm" class="addForm" :rules="addFormRules">
          <!-- 第一行 -->
          <el-row>
            <el-form-item label="培训课目名称" prop="trainName">
              <el-input v-model="addForm.trainName" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="培训老师" prop="trainTeacher">
              <el-input v-model="addForm.trainTeacher" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="培训机构" prop="trainCompany">
              <el-input v-model="addForm.trainCompany" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
          <!-- 第二行 -->
          <el-row>
            <el-form-item label="培训地点" prop="trainAddress">
              <el-input v-model="addForm.trainAddress" autocomplete="off"></el-input>
            </el-form-item>
            <!-- <el-form-item label="培训时长(小时)" prop="trainHours">
              <el-input v-input-limit="1" v-model="addForm.trainHours" autocomplete="off"></el-input>
            </el-form-item> -->
<!--            <el-form-item label="培训开始日期" prop="trainBeginDate">
              <el-input v-model="addForm.trainBeginDate" autocomplete="off"></el-input>
            </el-form-item> -->
            <el-form-item label="培训费(元)" prop="trainMoney">
              <el-input v-input-limit v-model="addForm.trainMoney" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="status">
              <el-select v-model="addForm.status" placeholder="请选择状态" filterable clearable>
                <el-option
                  :label="item.label"
                  :value="item.status"
                  v-for="(item, index) in statusList"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <!-- <el-form-item label="培训结束日期" prop="trainEndDate">
              <el-input v-model="addForm.trainEndDate" autocomplete="off"></el-input>
            </el-form-item> -->
                <el-form-item label="开始时间" prop="date1">
                      <el-date-picker v-model="addForm.trainBeginDate" type="datetime" placeholder="选择开始日期" default-time="12:00:00"></el-date-picker>
                </el-form-item>
                 <el-col class="line" :span="2">-</el-col>
                 <el-form-item label="开始时间" prop="date2">
                         <el-date-picker v-model="addForm.trainEndDate" type="datetime" placeholder="选择结束日期" default-time="12:00:00"></el-date-picker>
                 </el-form-item>
          </el-row>
          <el-row>
            <el-form-item label="备注" prop="remark">
              <el-input v-model="addForm.remark" autocomplete="off"></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addSure()">确 认</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'trainingCourse',
  components: {
  },
  data () {
    return {
      tabData: [], // 表格数据源
      form: { trainName: '' }, // 搜索表单
      total: 0, // 总数据条数
      paging: {
        pageSize: 10, // 每页显示数据条数
        PageNo: 1 // 当前页数
      }, // 分页表单
      orderDialogFormVisible: false, // 控制详情对话框的显示与隐藏
      addDialogFormVisible: false, // 控制添加对话框的显示与隐藏
      selectForm: {}, // 选中的表单对象
      addForm: {}, // 添加表单
      // is: true,
      addFormRules: {
        trainName: [
          { required: true, message: '请输入培训课目名称', trigger: 'blur' }
        ],
        trainTeacher: [
          { required: true, message: '请输入培训老师', trigger: 'blur' }
        ],
        trainCompany: [
          { required: true, message: '请输入培训机构', trigger: 'blur' }
        ],
        trainHours: [
          { required: true, message: '请输入培训时长', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        trainBeginDate: [
          { required: true, message: '请输入培训开始日期', trigger: 'blur' }
        ],
        trainEndDate: [
          { required: true, message: '请输入培训结束日期', trigger: 'blur' }
        ],
        trainAddress: [
          { required: true, message: '请输入培训地点 ', trigger: 'blur' }
        ]
      }, // 添加表单校验规则
      selectFormRules: {
        trainName: [
          { required: true, message: '请输入培训课目名称', trigger: 'blur' }
        ],
        trainTeacher: [
          { required: true, message: '请输入培训老师', trigger: 'blur' }
        ],
        trainCompany: [
          { required: true, message: '请输入培训机构', trigger: 'blur' }
        ],
        trainHours: [
          { required: true, message: '请输入培训时长', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        trainBeginDate: [
          { required: true, message: '请输入培训开始日期', trigger: 'blur' }
        ],
        trainEndDate: [
          { required: true, message: '请输入培训结束日期', trigger: 'blur' }
        ],
        trainAddress: [
          { required: true, message: '请输入培训地点 ', trigger: 'blur' }
        ]
      },
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      fileList: [],
      isDisabled: true, // 控制对话框的编辑
      statusList: [{ label: '新增', status: '0' }, { label: '正常', status: '1' }, { label: '停用', status: '2' }],
      personTypeList: [{ label: '驾驶员', status: '0' }, { label: '医护人员', status: '1' }],
      dioTotle: ''

    }
  },
  created () {
    this.getAllCar()
  },
  methods: {
    // 给表格头部设定背景色/vehicleServer/tInfoCertRecord/selecteTInfoVehicleService
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 获取所有随车人员培训科目
    async getAllCar () {
      const { data: res } = await this.$http.post('/pmService/tInfoTrain/getTInfoTrainList', {
        pageSize: this.paging.pageSize,
        pageNo: this.paging.PageNo
        // "status": 1
      })
      console.log(res)
      if (res.statusCode !== '200') return this.$message.error('获取培训科目列表失败')
      this.tabData = res.data.records
      this.total = +res.data.total
    },
    // 点击搜索按钮触发
    async search () {
      // 判断有输入账号
      if (this.form.trainName === '') {
        this.getAllCar()
      } else {
        // 调接口，进行查询
        const { data: res } = await this.$http.post('/pmService/tInfoTrain/getTInfoTrainList', {
          pageSize: this.paging.pageSize,
          pageNo: this.paging.PageNo,
          trainName: this.form.trainName
        })
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('查询失败')
        this.tabData = res.data.records
        this.total = +res.data.total
      }
    },
    // 切换每页显示数量时触发
    handleSizeChange (newSize) {
      this.paging.pageSize = newSize
      this.getAllCar()
    },
    // 切换页码时触发
    handlePageNumChange (PageNum) {
      this.paging.PageNo = PageNum
      this.getAllCar()
    },
    // 点击查看详情按钮触发
    look (v) {
      this.isDisabled = true
      this.selectForm = v
      this.orderDialogFormVisible = true
      this.dioTotle = '随车人员证书详情'
    },
    // 点击编辑按钮触发
    edit (v) {
      this.isDisabled = false
      this.selectForm = _.cloneDeep(v)
      this.orderDialogFormVisible = true
      this.dioTotle = '修改随车人员培训科目信息'
    },
    // 点击删除按钮触发
    open (e) {
      this.$confirm(`是否确认删除${e.trainName}`, '删除培训科目', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        try {
          const { data: res } = await this.$http.post('/pmService/tInfoTrain/deleteTInfoTrain', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (res.statusCode !== '200') return this.$message.error('删除失败')
          // 成功提示
          this.$message.success('删除成功')
          // 刷新视图
          this.getAllCar()
        } catch (e) {
          console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 点击详情确定框触发
    sure () {
      // 判断是出于查看详情还是编辑
      if (this.isDisabled) { return this.orderDialogFormVisible = false } else {
        // 进行编辑操作

        this.$refs.selectForm.validate(async valid => {
          if (!valid) return
          const { data: res } = await this.$http.post('/pmService/tInfoTrain/updateTInfoTrain', JSON.parse(JSON.stringify(this.selectForm)))

          // 判断请求是否成功
          if (res.statusCode !== '200') return this.$message.error('修改失败')
          // 成功提示
          this.$message.success('修改成功')
          // 重新渲染数据
          this.getAllCar()
          this.orderDialogFormVisible = false
        })
      }
    },
    // 添加机构--确定按钮
    addSure () {
      this.addForm.trainBeginDate = this.addForm.trainBeginDate ? this.formatTime(this.addForm.trainBeginDate, 'yyyy-MM-dd hh:mm:ss') : ''
      this.addForm.trainEndDate = this.addForm.trainEndDate ? this.formatTime(this.addForm.trainEndDate, 'yyyy-MM-dd hh:mm:ss') : ''
      // 预校验
      this.$refs.addForm.validate(async valid => {
        if (!valid) return
        console.log(JSON.parse(JSON.stringify(this.addForm)))
        // 调接口，发送添加客户请求
        const { data: res } = await this.$http.post('/pmService/tInfoTrain/addTInfoTrain', JSON.parse(JSON.stringify(this.addForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getAllCar()
        // 关闭弹框
        this.addDialogFormVisible = false
      })
    },
    // 监听添加用户对话框的关闭事件
    addDialogClose () {
      this.$refs.addForm.resetFields()
    },
    // 格式化时间
    formatTime: function (date, fmt) {
      var date = new Date(date)
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      var o = {
        'M+': date.getMonth() + 1,
        'd+': date.getDate(),
        'h+': date.getHours(),
        'm+': date.getMinutes(),
        's+': date.getSeconds()
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          var str = o[k] + ''
          fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length))
        }
      }
      return fmt
    }
  }
}
</script>
<style lang="less" scoped>
.trainingCourse {
  width: 100%;
  height: 100%;
  //隐藏滚动条
  ::-webkit-scrollbar {
    // display: none; /* Chrome Safari */
  }
  .father-card {
    .btns {
      margin-bottom: 10px;
    }
    .tab {
      /deep/ .el-table {
        margin-bottom: 20px;
        .el-table__row--striped td {
          background-color: #e4eaec !important;
        }
        .current-row > td {
          background-color: #66b1ff !important;
        }
      }
      .el-pagination {
        margin-bottom: 0.25rem;
        margin-right: 0.25rem;
        text-align: right;
      }
    }
    .add-dialog,
    .order-dialog {
      .dialog-Title {
        color: #0097fe;
        font-size: 20px;
        font-weight: 700;
      }
      /deep/ .el-form {
        .subtitle {
          color: #0097fe;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 20px;
          padding-left: 6px;
          border-left: 5px solid #0097fe;
        }
        .el-row {
          display: flex;
          .el-form-item {
            flex: 1;
            display: flex;
            .el-form-item__content {
              flex: 1;
              .el-input {
                width: 100%;
                .el-input__inner {
                  width: 100%;
                }
              }
              .el-select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
